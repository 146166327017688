import { template as template_796bca382e33466b8a89da8deddbcbfe } from "@ember/template-compiler";
const SidebarSectionMessage = template_796bca382e33466b8a89da8deddbcbfe(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
