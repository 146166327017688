import { template as template_b2d6f280715d400a94282cbcf04d5d14 } from "@ember/template-compiler";
const FKLabel = template_b2d6f280715d400a94282cbcf04d5d14(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
